$(document).on(('turbo:load'), () => {

  $(function() {
    $("button[data-toggle-modal]").each(function() {
      $(this).on("click", function() {

        const modalId = $(this).data("toggle-modal")
        $(`#${modalId}`).toggleClass("is-active")
      })
    })
  })

});
