import $ from 'jquery';
import 'selectize';
import { CountryStateSelect, standAloneFindStates } from "../utilities/country_state_select";

$(document).on('turbo:before-cache', function() {
  $('.selectized').each(function(){
    if (this.selectize != undefined) {
      this.selectize.destroy()
    }
  })
});

$(document).on(('turbo:load'), () => {

  var hospitals = [];

  if ($('#hospital_name').length) {

    $.ajax({
      type: "GET",
      url: "/hospitals",
      success: function(response) {
        response.data.forEach(function(hospitalData) {
          hospitals.push({
            id: hospitalData.id,
            name: hospitalData.name,
            address: hospitalData.streetAddress,
            city: hospitalData.city,
            state: hospitalData.state,
            zip: hospitalData.zip,
            country: hospitalData.country,
            authorityName: hospitalData.authorityName,
            new_hospital: false,
            active: hospitalData.active
          })
        })

        $('#hospital_name').selectize({
          create: true,
          sortField: "text",
          maxItems: 1,
          valueField: 'id',
          labelField: 'name',
          searchField: ["name"],
          options: hospitals,
          sortField: {
            field: 'active',
            direction: 'desc',
          },
          render: {
            item: function(item, escape) {
                return '<div>' +
                    (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '')
                '</div>';
            },
            option: function(item, escape) {
                var label = item.name
                const buttonLabel = item.active ? "Mark as inactive" : "Mark as active"
                const inactiveStyle = item.active ? "selectize-dropdown-content" : "selectize-dropdown-content -inactive"

                return `<div class="container ${inactiveStyle}" id="container-${item.id}">` +
                    '<div class="columns is-mobile">' +
                      '<div class="column is-9">' +
                        '<div class="has-text-weight-bold">' + escape(label) + '</div>' +
                        '<div>' + escape(item.address) + '</div>' +
                        '<div>' + escape(`${item.city}, ${item.state}, ${item.zip}`) + '</div>' +
                        '<div>' + escape(item.country) + '</div>' +
                        '<div>' + escape(item.authorityName) + '</div>' +
                      '</div>' +
                      '<div class="column is-3">' +
                        `<a href="javascript:void(0)" class="toggle-status-button" id="hospital-option-${item.id}" data-hospital-id=${item.id} data-hospital-active=${item.active}>` + escape(buttonLabel) + '</a>' +
                      '</div>' +
                    '</div>' +
                '</div>';
            }
          },
          onInitialize: function() {
            $('.selectize-dropdown-content').data("button-pressed", false)
          },
          onDropdownOpen: function() {
            $('.selectize-dropdown-content').on('mousedown', 'div[data-selectable] a.toggle-status-button', function(event) {
              $('.selectize-dropdown-content').data("button-pressed", true)
              event.preventDefault();
              event.stopPropagation();

              const hospitalId = $(this).data("hospital-id")
              const hospitalActive = $(this).data("hospital-active")

              $.ajax({
                type: "POST",
                url: `/hospitals/${hospitalId}/set_user_preference`,
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: {
                  status: hospitalActive ? "inactive" : "active"
                },
                success: function() {
                  const option = $(`#hospital-option-${hospitalId}`)
                  option.data("hospital-active", !hospitalActive)
                  
                  const optionButtonLabel = option.data("hospital-active") ? "Mark as inactive" : "Mark as active"
                  const selectedHospitalId = $("#hospital_id").val();
                  const selectedHospitalFields = $(`#container-${hospitalId}`)

                  if (selectedHospitalId == hospitalId) {
                    $('.selectize-dropdown-content').data("button-pressed", false)
                  }

                  option.text(optionButtonLabel)
                  
                  if (option.data("hospital-active")) {
                    selectedHospitalFields.removeClass("selectize-dropdown-content -inactive")
                  } else {
                    selectedHospitalFields.addClass("selectize-dropdown-content -inactive")
                  }
                },
              });
              return false
            });
          },
          onChange: function(value) {
            const buttonWasPressed = $('.selectize-dropdown-content').data("button-pressed")
            const selectedHospitalId = $("#hospital_id").val();
            const newHospitalId = value

            if (buttonWasPressed) {
              const hospitalName = $(this);

              if (selectedHospitalId === "") {
                hospitalName[0].setValue("");
              }

              if (selectedHospitalId !== newHospitalId) {
                hospitalName[0].setValue(selectedHospitalId);
              }
            } else {
              var selectedHospital = {};
              hospitals.forEach(function(hospital) {
                if(value == hospital.id)
                {
                  selectedHospital = hospital;
                }
              })
              if (selectedHospital) {
                $("#hospital_id").val(value);
                $("#hospital_street_address").val(selectedHospital.address);
                $("#hospital_unit").val(selectedHospital.unit);
                $("#hospital_city").val(selectedHospital.city);
                $("#hospital_country").val(selectedHospital.country || "US")
                standAloneFindStates((selectedHospital.country || "US"), "hospital_state", selectedHospital.state)
                $("#hospital_state").val(selectedHospital.state);
                $("#hospital_zip").val(selectedHospital.zip);
                $("#hospital_new_hospital").val(selectedHospital.new_hospital);
                $("#next-button").prop("disabled", false)
              }
              else {
                $('.selectize-dropdown-content').data("button-pressed", false)
                return false
              }
            }
            $('.selectize-dropdown-content').data("button-pressed", false)
          },
          create: function(value) {
            $("#hospital_name").val(value);
            $("#hospital_street_address").val(undefined);
            $("#hospital_street_address").val(undefined);
            $("#hospital_unit").val(undefined);
            $("#hospital_city").val(undefined);
            $("#hospital_state").val(undefined);
            console.log("CREATE BEING SET")
            //$("#hospital_country").val("US");
            $("#hospital_zip").val(undefined);
            $("#hospital_new_hospital").val(true);

            var hospital_object = {
              id: `${value}`,
              name: value,
              address: undefined,
              city: undefined,
              state: undefined,
              zip: undefined,
              country: undefined,
              new_hospital: true
            }

            hospitals.push(hospital_object);
            // NOTE: Id has to be unique so we set it 0_value to allow the user to change their mind
            // NOTE: In ruby we will check for the _
            return hospital_object;
          }
        });
      }
    })
  }

  if ($("#hospital_country").length) {

    CountryStateSelect({
      country_id: "hospital_country",
      state_id: "hospital_state"
    });

    standAloneFindStates($("#hospital_country").val(), "hospital_state", $("#hospital_state_value_hidden").val())
  }
  
});

