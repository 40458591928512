// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start();
import "@hotwired/turbo-rails";
require("@rails/activestorage").start();
require("./../channels");
require("jquery");
require("selectize");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Make it globally available
import 'selectize';
import "../utilities/country_state_select";
import "../utilities/toggle_modal";
import "../utilities/dropdown";
import "../utilities/navbar";

require("../components/clinical_categories");
require("../components/hospitals");
require("../components/professional_courses");
require("../components/surgeons");

import $ from 'jquery';
window.$ = window.jQuery = require("jquery");

function addBorder(event) {
    if ($(event.target).val() !== "") {
        $(event.target).addClass("-border")
    } else {
        $(event.target).removeClass("-border")
    }
}


function removeEmptyState(event) {
    if ($(event.target).val() !== "") {
        $(event.target).removeClass("-empty")
    } else {
        $(event.target).addClass("-empty")
    }
}

function addBorderTwo(event) {
    $(event.target).addClass("-border")
}
$(document).on("turbo:load", () => {





});